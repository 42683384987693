/*
@tailwind base;

@tailwind components;
*/

.rounded-4 {
  border-radius: .4rem
}

.rounded-6 {
  border-radius: .6rem
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-16 {
  border-radius: 1.6rem
}

.rounded {
  border-radius: .4rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px
}

.border-1 {
  border-width: 1px
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.hidden {
  display: none
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.self-start {
  align-self: flex-start
}

.self-center {
  align-self: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-grow {
  flex-grow: 1
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.float-right {
  float: right
}

.font-300 {
  font-weight: 300
}

.font-400 {
  font-weight: 400
}

.font-500 {
  font-weight: 500
}

.font-light {
  font-weight: 300
}

.font-medium {
  font-weight: 500
}

.font-bold {
  font-weight: 700
}

.h-16 {
  height: 1.6rem
}

.h-20 {
  height: 2rem
}

.h-40 {
  height: 4rem
}

.h-52 {
  height: 5.2rem
}

.h-56 {
  height: 5.6rem
}

.h-64 {
  height: 6.4rem
}

.h-72 {
  height: 7.2rem
}

.h-96 {
  height: 9.6rem
}

.h-136 {
  height: 13.6rem
}

.h-160 {
  height: 16rem
}

.h-200 {
  height: 20rem
}

.h-224 {
  height: 22.4rem
}

.h-512 {
  height: 51.2rem
}

.h-full {
  height: 100%
}

.text-10 {
  font-size: 1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-14 {
  font-size: 1.4rem
}

.text-16 {
  font-size: 1.6rem
}

.text-18 {
  font-size: 1.8rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-28 {
  font-size: 2.8rem
}

.text-32 {
  font-size: 3.2rem
}

.text-40 {
  font-size: 4rem
}

.text-52 {
  font-size: 5.2rem
}

.text-56 {
  font-size: 5.6rem
}

.text-64 {
  font-size: 6.4rem
}

.leading-none {
  line-height: 1
}

.m-24 {
  margin: 2.4rem
}

.m-32 {
  margin: 3.2rem
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.my-20 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.mx-20 {
  margin-left: 2rem;
  margin-right: 2rem
}

.mx-32 {
  margin-left: 3.2rem;
  margin-right: 3.2rem
}

.my-44 {
  margin-top: 4.4rem;
  margin-bottom: 4.4rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-mx-8 {
  margin-left: -0.8rem;
  margin-right: -0.8rem
}

.mr-0 {
  margin-right: 0
}

.mb-2 {
  margin-bottom: 0.2rem
}

.mr-4 {
  margin-right: 0.4rem
}

.ml-4 {
  margin-left: 0.4rem
}

.ml-6 {
  margin-left: 0.6rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-8 {
  margin-right: 0.8rem
}

.mb-8 {
  margin-bottom: 0.8rem
}

.ml-8 {
  margin-left: 0.8rem
}

.ml-10 {
  margin-left: 1.0rem
}

.mt-12 {
  margin-top: 1.2rem
}

.ml-12 {
  margin-left: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mr-16 {
  margin-right: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.ml-16 {
  margin-left: 1.6rem
}

.mt-20 {
  margin-top: 2rem
}

.mb-20 {
  margin-bottom: 2rem
}

.mt-24 {
  margin-top: 2.4rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.ml-24 {
  margin-left: 2.4rem
}

.ml-28 {
  margin-left: 2.8rem
}

.mt-32 {
  margin-top: 3.2rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.mb-36 {
  margin-bottom: 3.6rem
}

.mt-40 {
  margin-top: 4rem
}

.mb-40 {
  margin-bottom: 4rem
}

.ml-52 {
  margin-left: 5.2rem
}

.mr-64 {
  margin-right: 6.4rem
}

.mt-auto {
  margin-top: auto
}

.-mb-10 {
  margin-bottom: -1rem
}

.-mt-24 {
  margin-top: -2.4rem
}

[dir='ltr'] .ltr\:ml-4,[dir='ltr'].ltr\:ml-4 {
  margin-left: 0.4rem
}

[dir='ltr'] .ltr\:ml-8,[dir='ltr'].ltr\:ml-8 {
  margin-left: 0.8rem
}

[dir='rtl'] .rtl\:mr-4,[dir='rtl'].rtl\:mr-4 {
  margin-right: 0.4rem
}

[dir='rtl'] .rtl\:mr-8,[dir='rtl'].rtl\:mr-8 {
  margin-right: 0.8rem
}

.max-w-384 {
  max-width: 38.4rem
}

.max-w-512 {
  max-width: 51.2rem
}

.max-w-full {
  max-width: 100%
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-72 {
  min-height: 7.2rem
}

.min-h-136 {
  min-height: 13.6rem
}

.min-h-160 {
  min-height: 16rem
}

.min-h-640 {
  min-height: 64rem
}

.min-w-0 {
  min-width: 0
}

.min-w-40 {
  min-width: 4rem
}

.min-w-48 {
  min-width: 4.8rem
}

.min-w-136 {
  min-width: 13.6rem
}

.opacity-75 {
  opacity: 0.75
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-visible {
  overflow: visible
}

.overflow-scroll {
  overflow: scroll
}

.overflow-x-auto {
  overflow-x: auto
}

.p-0 {
  padding: 0
}

.p-4 {
  padding: 0.4rem
}

.p-8 {
  padding: 0.8rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-24 {
  padding: 2.4rem
}

.p-32 {
  padding: 3.2rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-2 {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem
}

.pt-0 {
  padding-top: 0
}

.pb-0 {
  padding-bottom: 0
}

.pl-0 {
  padding-left: 0
}

.pt-4 {
  padding-top: 0.4rem
}

.pr-4 {
  padding-right: 0.4rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pr-8 {
  padding-right: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pt-12 {
  padding-top: 1.2rem
}

.pr-12 {
  padding-right: 1.2rem
}

.pl-12 {
  padding-left: 1.2rem
}

.pt-16 {
  padding-top: 1.6rem
}

.pr-16 {
  padding-right: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pt-20 {
  padding-top: 2rem
}

.pb-20 {
  padding-bottom: 2rem
}

.pl-20 {
  padding-left: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pr-28 {
  padding-right: 2.8rem
}

.pb-28 {
  padding-bottom: 2.8rem
}

.pt-32 {
  padding-top: 3.2rem
}

.pr-32 {
  padding-right: 3.2rem
}

.pl-32 {
  padding-left: 3.2rem
}

.pl-40 {
  padding-left: 4rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pr-60 {
  padding-right: 6rem
}

.pl-60 {
  padding-left: 6rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.resize {
  resize: both
}

.shadow-1 {
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.shadow-none {
  box-shadow: none
}

.hover\:shadow-2:hover {
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-black {
  --text-opacity: 1;
  color: #22292F;
  color: rgba(34, 41, 47, var(--text-opacity))
}

.text-white {
  --text-opacity: 1;
  color: #FFF;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.text-red {
  --text-opacity: 1;
  color: #F44336;
  color: rgba(244, 67, 54, var(--text-opacity))
}

.text-green {
  --text-opacity: 1;
  color: #4CAF50;
  color: rgba(76, 175, 80, var(--text-opacity))
}

.uppercase {
  text-transform: uppercase
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.visible {
  visibility: visible
}

.whitespace-normal {
  white-space: normal
}

.whitespace-no-wrap {
  white-space: nowrap
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.break-words {
  overflow-wrap: break-word
}

.break-all {
  word-break: break-all
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-16 {
  width: 1.6rem
}

.w-20 {
  width: 2rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-64 {
  width: 6.4rem
}

.w-200 {
  width: 20rem
}

.w-224 {
  width: 22.4rem
}

.w-256 {
  width: 25.6rem
}

.w-288 {
  width: 28.8rem
}

.w-xs {
  width: 32rem
}

.w-1\/2 {
  width: 50%
}

.w-1\/4 {
  width: 25%
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.gap-4 {
  grid-gap: 0.4rem;
  gap: 0.4rem
}

.gap-8 {
  grid-gap: 0.8rem;
  gap: 0.8rem
}

.gap-10 {
  grid-gap: 1.0rem;
  gap: 1.0rem
}

.gap-16 {
  grid-gap: 1.6rem;
  gap: 1.6rem
}

.gap-32 {
  grid-gap: 3.2rem;
  gap: 3.2rem
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
}

@media (min-width: 600px) {

  .sm\:rounded-16 {
    border-radius: 1.6rem
  }

  .sm\:flex {
    display: flex
  }

  .sm\:h-136 {
    height: 13.6rem
  }

  .sm\:h-320 {
    height: 32rem
  }

  .sm\:text-14 {
    font-size: 1.4rem
  }

  .sm\:text-20 {
    font-size: 2rem
  }

  .sm\:mr-12 {
    margin-right: 1.2rem
  }

  .sm\:mb-12 {
    margin-bottom: 1.2rem
  }

  .sm\:min-h-136 {
    min-height: 13.6rem
  }

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  .sm\:pb-16 {
    padding-bottom: 1.6rem
  }
}

@media (min-width: 960px) {

  .md\:flex {
    display: flex
  }

  .md\:hidden {
    display: none
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  .md\:gap-0 {
    grid-gap: 0;
    gap: 0
  }

  .md\:gap-64 {
    grid-gap: 6.4rem;
    gap: 6.4rem
  }
}

@media (min-width: 1280px) {

  .lg\:pr-0 {
    padding-right: 0
  }

  .lg\:pl-0 {
    padding-left: 0
  }

  [dir='ltr'] .lg\:ltr\:pl-0,[dir='ltr'].lg\:ltr\:pl-0 {
    padding-left: 0
  }

  [dir='rtl'] .lg\:rtl\:pr-0,[dir='rtl'].lg\:rtl\:pr-0 {
    padding-right: 0
  }
}
